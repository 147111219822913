.container {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
}

h1 {
    font-size: 2em;
    color: #007BFF;
}

h2 {
    font-size: 1.5em;
    color: #6C757D;
    margin-top: 20px;
}

p {
    font-size: 1em;
    line-height: 1.6;
    margin-top: 10px;
}
.link-button {
    background: none;
    color: blue;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    text-decoration: none;
}